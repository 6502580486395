import { useState } from 'react'
import { Route } from 'react-router-dom'
import LoginForm from '../components/LoginForm'
import { useAuth } from '@lib/hooks'

const Login = () => {
  const { startLogin, confirmLogin, showLoginConfirm } = useAuth()

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const handleEmailSubmit = async email => {
    setEmail(email)
    setError('')

    try {
      await startLogin(email)
    } catch (ex) {
      setError(ex.message)
    }
  }

  const handleLoginCodeSubmit = async loginCode => {
    try {
      const res = await confirmLogin(email, loginCode)
      if (!res.accessToken) {
        setError('Invalid code, please try again')
        return
      }
    } catch (ex) {
      setError(ex.message)
    }
  }

  return (
    <LoginForm
      email={email}
      emailSubmitted={showLoginConfirm}
      error={error}
      onEmail={handleEmailSubmit}
      onLoginCode={handleLoginCodeSubmit}
    />
  )
}

export const AuthenticatedRoute = ({ children, ...routeArgs }) => {
  const { user } = useAuth()
  return <Route {...routeArgs}>
    {user === null ? <Login /> : children}
  </Route>
}
